import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { IndberetningstypeAwareness } from "../../../shared/helpers/Indberetningstype-awareness";
import { Fejl } from "../../../shared/models/fejl.model";
import { ErrorService } from "../../../shared/services/error/error.service";
import { TitleService } from '../../../shared/services/title/title.service';
import { TranslationService } from "../../../shared/services/translate/translation.service";
import { WriteAccessCheckSubscriberService } from "../../../shared/services/write-access-check-subscriber/write-access-check-subscriber.service";
import { getStatusColorClass } from '../../../shared/utils/css-utils';
import { standardOverskrift } from "../../../shared/utils/fejl-utils";
import { waitForPligtigAndPerformAction } from "../../../shared/utils/session-storage-utils";
import { formatDate, removeCRSdocRefPrefix, removeFATCAdocRefPrefix } from "../../../shared/utils/text-utils";
import { GetRecordsModel } from '../../models/get-records.model';
import { HistoryFilterPossibleValuesModel } from '../../models/history-filter-possible-values.model';
import { HistoryFilterValuesModel } from '../../models/history-filter-values.model';
import { HistoryRecordModel } from "../../models/history-record.model";
import { HentFilService } from "../../services/hent-fil/hent-fil.service";
import { HentHistorikService } from "../../services/hent-historik/hent-historik.service";

@Component({
    templateUrl: './historik.component.html',
    providers: [HentHistorikService, HentFilService],
    styleUrls: ['./historik.component.css', '../../../shared/css/filter-panel.css', '../../../shared/css/paginering.css']
})
export class HistorikComponent extends IndberetningstypeAwareness implements OnInit {

    allReports: HistoryRecordModel[] = [];                      // All reports in history

    errors:ErrorService;
    errorsOverskrift:string;

    formatDate = formatDate;

    collapse:boolean = true;

    yearKeyForAlle: string = "";
    statusKeyForAlle:string = "";

    filterPossibleValues: HistoryFilterPossibleValuesModel;
    filterValues: HistoryFilterValuesModel;

    // actualPageSize holds a _valid_ page size. Most often it will hold the same value as pageSizeInput, but in cases
    // where pageSizeInput is contextually invalid, actualPageSize holds the latest valid value
    actualPageSize: number = 20;
    // pageSizeInput holds the value (valid or not) currently held in pagesize input-field
    pageSizeInput: number = 20;

    offset: number = 0;
    totalPages: number;
    totalCount: number;
    currentPageInput: number = 1;
    actualCurrentPage: number = 1;

    constructor(protected router: Router,
                public writeAccessCheckSubscriberService: WriteAccessCheckSubscriberService,
                private translationService: TranslationService,
                private oversigtService: HentHistorikService,
                private errorService: ErrorService,
                private hentFilService: HentFilService,
                private titleService: TitleService) {
        super();
        this.errors = errorService;
        this.errorsOverskrift = standardOverskrift();
        this.filterPossibleValues = new HistoryFilterPossibleValuesModel();
        this.filterValues = new HistoryFilterValuesModel();
        this.pageSizeInput = this.actualPageSize;
    }

    ngOnInit() {
        this.translationService.environmentAwareTranslate('OVERSIGT.SEARCH.YEAR.DEFAULT').subscribe((res: string) => {
            this.yearKeyForAlle = res;
            this.filterValues.year = res;
        });
        this.translationService.environmentAwareTranslate('OVERSIGT.SEARCH.STATUS.DEFAULT').subscribe((res: string) => {
            this.statusKeyForAlle = res;
            this.filterValues.status = res;
        });
        this.subscribeToFejlVedFilDownload();
        this.subscribeToOverviewChanges();
        this.translationService.environmentAwareTranslate('OVERSIGT.HENTERINDHOLD').subscribe((res: string) => {
            page.showLoader(res);
            waitForPligtigAndPerformAction(() => {
                this.switchedPageSize();
            }, () => {
                page.hideLoader();
            });
        });
    }

    goToPageAndUpdatePagingParams(pageNumber: number) {
        this.offset = (pageNumber - 1) * this.actualPageSize;
        this.oversigtService.getReportsOnPage(this.offset, this.actualPageSize,
            this.filterValues.getYearFilterValue(this.yearKeyForAlle),
            this.filterValues.getMessageRefIdFilterValue(),
            this.filterValues.getStatusFilterValue(this.statusKeyForAlle));
        this.actualCurrentPage = pageNumber;
        this.currentPageInput = this.actualCurrentPage;
    }

    nextPage(): void {
        if(this.totalPages > this.actualCurrentPage) {
            this.goToPageAndUpdatePagingParams(this.actualCurrentPage + 1);
        }
    }

    previousPage(): void {
        if(this.actualCurrentPage > 1) {
            this.goToPageAndUpdatePagingParams(this.actualCurrentPage - 1);
        }
    }

    goToPage(): void {
        if(this.totalPages >= this.currentPageInput && this.currentPageInput > 0) {
            this.goToPageAndUpdatePagingParams(this.currentPageInput);
            this.actualCurrentPage = this.currentPageInput;
        }
    }

    refreshCurrentPageWithFilters(): void {
        this.goToPageAndUpdatePagingParams(1);
    }
    /**
     * Returns true if currentPageInput is out of bounds or not a number
     */
    get pagePickerInputHasError(): boolean {
        return this.currentPageInput === null || this.currentPageInput > this.totalPages || this.currentPageInput < 1;
    }

    hasWriteAccess(): boolean {
        return this.writeAccessCheckSubscriberService.hasWriteAccess();
    }

    switchedPageSize() {
        this.errors.reset();

        // Check if input number is valid before proceeding
        if (this.invalidPageSizeEntered) {
            return;
        }

        this.actualPageSize = this.pageSizeInput;

        let newPageNumber = this.actualCurrentPage;
        if(newPageNumber > this.totalCount / this.actualPageSize) {
            newPageNumber = Math.max(1, Math.ceil(this.totalCount / this.actualPageSize));
        }
        this.goToPageAndUpdatePagingParams(newPageNumber);
    }

    subscribeToOverviewChanges() {
        this.oversigtService.reportListCompleted.subscribe(
            (reportsModel: GetRecordsModel) => {
                this.totalCount = reportsModel.totalCount;
                this.totalPages = reportsModel.totalPages;
                this.allReports = reportsModel.records;
                page.hideLoader();
            }
        );

        this.oversigtService.responseError.subscribe(
            (fejl: Fejl) => {
                if (fejl) {
                    page.hideLoader();
                    this.errors.tilfoejFejl([fejl]);
                }
            }
        );
    }

    subscribeToFejlVedFilDownload() {
        this.hentFilService.fejlVedHentningAfFil.subscribe(
            (fejl: any) => {
                this.errors.resetOgTilfoejFejlbesked("OVERSIGT.FEJL_VED_FIL_DOWNLOAD");
            });
    }

    getStatusColor(status: string): any {
        return getStatusColorClass(status);
    }

    hentFil(url: string, messageRefId: string) {
        this.hentFilService.hentFil(url, messageRefId);
    }

    overskrift(): string {
        return this.titleService.setTitleFromKey('OVERSIGT.OVERSKRIFT');
    }

    /**
     * Page picker should be disabled when there is only one page to show
     */
    get pagePickerShouldBeDisabled():boolean {
        return this.totalPages === 1;
    }

    /**
     * Previous page button should be disabled when we're on the first page
     */
    get goToPreviousPageShouldBeDisabled():boolean {
        return this.actualCurrentPage === 1;
    }

    /**
     * Next page button should be disabled when we're on the last page
     */
    get goToNextPageShouldBeDisabled():boolean {
        return this.actualCurrentPage === this.totalPages;
    }

    /**
     * Returns true when the entered page size is invalid (null, zero or negative)
     * Entering text in inputfield such as 'e' will effectively assign null to pageSizeInput
     */
    get invalidPageSizeEntered(): boolean {
        if (this.pageSizeInput === null) {
            // This may happen if the user enters 'e' (Euler's number) (which input-field happens to accept)
            return true;
        }
        if (this.pageSizeInput < 1) {
            return true;
        }
        return false;
    }
}
