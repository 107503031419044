import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { Fejl } from "../../../shared/models/fejl.model";
import { isDefined } from "../../../shared/utils/text-utils";
import { genererUrlTilAccountReports, genererUrlTilSletAccountReport } from '../../../shared/utils/url-utils';

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json;charset=utf-8'}),
    withCredentials: true
};

@Injectable()
export class KontoejereService {

    reportList: Subject<any> = new Subject<any>();
    errorResponses: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient) {
    }

    /**
     * Henter kontooverblikket.
     * @param period perioden for hvilken data skal hentes
     * @param sortBy (valgfri) navn på kolonnen, der skal sorteres efter
     * @param ascending (valgfri) om der skal sorteres i stigende rækkefølge
     */
    public hentKontoejere(period: string, sortBy: string = null, ascending: boolean = false) {
        const path: string = genererUrlTilAccountReports(period, sortBy, ascending);

        this.http.get<any>(path, httpOptions)
            .subscribe(
                (res: any) => {
                    this.extractData(res);
                },
                error => {
                    this.handleError();
                }
            );
    }

    public sletAccountReports(reportingYear: string, arDocRefIds:string[]) {
        const path: string = genererUrlTilSletAccountReport();
        const body = {
            "reportingYear" : reportingYear,
            "arDocRefIds" : arDocRefIds
        };
        this.http.post(path, body, httpOptions)
            .map(res => {
                return res;
            })
            .subscribe(
                () => {},
                error => {
                    this.handleError();
                }
            );
    }

    private extractData(body: any) {
        if (body && isDefined(body.accountReports)) {
            this.reportList.next(body.accountReports);
        } else {
            this.errorResponses.next(new Fejl('KONTOEJEROVERSIGT.FEJL.SERVICEKALD'));
        }
    }

    private handleError() {
        this.errorResponses.next(new Fejl('KONTOEJEROVERSIGT.FEJL.SERVICEKALD'));
    }
}
