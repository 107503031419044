import { HistoryRecordModel } from '../../tidligere-indberetninger/models/history-record.model';
import { OverviewItemModel } from '../../tidligere-indberetninger/models/overview-item.model';
import { IndberetningstypeAwareness } from "../helpers/Indberetningstype-awareness";

export const TIDLIGSTE_AAR_FOR_INDBERETNING_FATCA:number = 2014;
export const TIDLIGSTE_AAR_FOR_INDBERETNING_CRS:number = 2016;
export const MAKS_ANTAL_AAR_BAGUD_TID:number = 5;
/**
 * Beregner de år, det er muligt at indberette for. Reglen er, at:
 * - Der må TIDLIGST indberettes for 2016
 * - Der må indberettes 5 år tilbage i tiden - dog skal man huske på, at nuværende år != indberetningsåret,
 *   dvs. hvis det faktiske år er 2030, må der indberettes for 2029-2025 (begge inkl.)
 * @returns {Array}
 */
export function genererIndberetningsaarListe(): number[] {
    let nuvaerendeAar:number = new Date().getFullYear()-1;
    const tilladteAar = [];
    let maksAntalAarTilbage = MAKS_ANTAL_AAR_BAGUD_TID;
    const tidligsteAar = IndberetningstypeAwareness.statics.erCrs() ? TIDLIGSTE_AAR_FOR_INDBERETNING_CRS : TIDLIGSTE_AAR_FOR_INDBERETNING_FATCA;
    while (maksAntalAarTilbage > 0 && nuvaerendeAar >= tidligsteAar) {
        tilladteAar.push(nuvaerendeAar);
        nuvaerendeAar--;
        maksAntalAarTilbage--;
    }
    return tilladteAar;
}

export function genererIndberetningsaarListeForHistorik(): string[] {
    let nuvaerendeAar:number = new Date().getFullYear()-1;
    const tilladteAar = [];
    const tidligsteAar = IndberetningstypeAwareness.statics.erCrs() ? TIDLIGSTE_AAR_FOR_INDBERETNING_CRS : TIDLIGSTE_AAR_FOR_INDBERETNING_FATCA;
    while (nuvaerendeAar >= tidligsteAar) {
        tilladteAar.push(nuvaerendeAar.toString());
        nuvaerendeAar--;
    }
    return tilladteAar;
}

export function genererFinansielleInstitutter(list:OverviewItemModel[]): string[] {
    const result: string[] = [];
    for (const k of list) {
        if (result.indexOf(k.fiDocRefId) === -1) {
            result.push(k.fiDocRefId);
        }
    }
    return result.sort();
}

export function genererFiGrupper(list:OverviewItemModel[]): string[] {
    const result: string[] = [];
    for (const k of list) {
        if (result.indexOf(k.fiDocRefIdAndNameGroup()) === -1) {
            result.push(k.fiDocRefIdAndNameGroup());
        }
    }
    return result.sort();
}

export function genererStatuserForHistorik(list:HistoryRecordModel[]): string[] {
    const result: string[] = [];
    for (const k of list) {
        if (result.indexOf(k.status) === -1) {
            result.push(k.status);
        }
    }
    return result.sort();
}
