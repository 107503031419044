import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { getIndberetningstype, getPligtig } from './session-storage-utils';
import { isDefinedAndNotEmpty } from './text-utils';

export function backendUrl(): string {
    return environment.apiUrl;
}

export function configUrl(): string {
    return backendUrl() + "/user/details";
}

export function accessWriteCheckUrl(): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/access/writecheck/";
}

export function accessCheckUrl(): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/access/check/";
}

export function landeUrl(): string {
    return backendUrl() + "/lande";
}

export function genererUrlTilFiluploadIndberetning(): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/databeskeder";
}

export function genererUrlTilEnkeltindberetning(): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/manuel/enkeltindberetninger";
}

export function genererUrlTilNulindberetning(): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/manuel/nulindberetninger";
}

export function genererUrlTilOversigt(): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/manuel/indberetningsoverblik";
}

export function genererUrlTilOversigtMedOffset(offset: number, pageSize: number, yearFilterValue: string, messageRefIdFilterValue: string, statusFilterValue: string): string {
    let url: string = genererUrlPrefixMedTypeOgPligtig() + "/manuel/indberetningsoverblik";

    url = url + "?pageSize=" + pageSize;
    url = url + "&offset=" + offset;

    if (yearFilterValue != null) {
        url = url + "&reportingYear=" + yearFilterValue;
    }
    if (messageRefIdFilterValue != null) {
        url = url + "&messageRefId=" + messageRefIdFilterValue;
    }
    if (statusFilterValue != null) {
        url = url + "&receiptStatus=" + statusFilterValue;
    }

    return url;
}

export function genererUrlTilProcesseringsstatus(): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/manuel/processeringsstatus";
}

export function genererUrlTilAccountReports(period: string, sortBy: string, ascending: boolean): string {
    const sortParams = sortBy ? `&sortBy=${sortBy}&sortAscending=${ascending}` : '';
    return genererUrlPrefixMedTypeOgPligtig() + `/manuel/kontooverblik?periode=${period}${sortParams}`;
}

export function genererUrlTilSletAccountReport(): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/manuel/sletninger";
}

export function genererUrlTilFIListe(period: string): string {
    return genererUrlPrefixMedTypeOgPligtig() + "/manuel/afsenderoverblik?periode=" + period;
}

function genererUrlPrefixMedTypeOgPligtig(): string {
    const pligtig = getPligtig();
    const indberetningstype = getIndberetningstype().toLowerCase();
    return backendUrl() + "/" + indberetningstype + "/pligtige/" + pligtig;
}

//Kan ikke testes idét den trigger et hard reload og tests kører i en test context.
export function getUrlParam(name: string) {
    const regex = new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)');
    const urlName = regex.exec(location.href);
    if (urlName) {
        return decodeURIComponent(urlName[1]);
    } else {
        return null;
    }
}

export function goToPortal(router: Router) {
    const url = getIndberetningstype() === "crs" ? environment.portalUrlCrs : environment.portalUrlFatca;
    if (!isDefinedAndNotEmpty(url)) {
        router.navigate(["/forside"]);
    } else {
        window.location.href = url;
    }
}

export const pathsWhereDataCanBeLost: Array<string> = [
    "enkeltindberetning",
    "enkeltindberetning/kontoejer",
    "enkeltindberetning/kontrollerende-person",
];
